import { configureStore } from "@reduxjs/toolkit";
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from "@reduxjs/toolkit/query";
import { loginWithPassword, userInfo } from "./services/auth";
import { patients } from "./services/patient";
import { bookings } from "./services/booking";
import { careproviders } from "./services/careprovider";
import { trainings } from "./services/training";
import { coursesessions } from "./services/coursesession";
import { trialreports } from "./services/trialreport";
import { appversions } from "./services/tech/appversion";
import { livesessions } from "./services/livesession";
import { system } from "./services/system";

const slices = [
  loginWithPassword,
  userInfo,
  patients,
  bookings,
  careproviders,
  trainings,
  coursesessions,
  trialreports,
  appversions,
  livesessions,
  system,
];

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    ...slices.reduce((acc, cv) => {
      return {
        ...acc,
        [cv.reducerPath]: cv.reducer,
      };
    }, {}),
    // [loginWithPassword.reducerPath]: loginWithPassword.reducer,
    // [userInfo.reducerPath]: userInfo.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(slices.map((slice) => slice.middleware)),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
