import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { lazy, Suspense } from "react";
import Loader from "components/Loader";

import { ROUTES } from "./routes";
import PublicLayout from "./PublicLayout";
import PrivateLayout from "./PrivateLayout";

// Unauthorized
const Login = lazy(() => import("pages/Login"));
const Auth = lazy(() => import("pages/Auth"));

// Authorized
const Dashboard = lazy(() => import("pages/Dashboard"));

const Patient = lazy(() => import("pages/Patient"));
const PatientDetail = lazy(() => import("pages/Patient/PatientDetail"));

const Booking = lazy(() => import("pages/Booking"));
const BookingDetail = lazy(() => import("pages/Booking/BookingDetail"));

const CareProvider = lazy(() => import("pages/CareProvider"));
const CareProviderDetail = lazy(
  () => import("pages/CareProvider/CareProviderDetail"),
);

const CourseSession = lazy(() => import("pages/CourseSession"));
const CourseSessionDetail = lazy(
  () => import("pages/CourseSession/CourseSessionDetail"),
);
const Reports = lazy(() => import("pages/Reports"));
const Training = lazy(() => import("pages/Training"));
const TrialSlots = lazy(() => import("pages/TrialSlots"));
const TrialAnalysis = lazy(() => import("pages/TrialSlotAnalysis"));

const Tech = lazy(() => import("pages/Tech"));
const AppVersion = lazy(() => import("pages/Tech/AppVersion"));

const router = createBrowserRouter([
  {
    element: <PublicLayout />,
    children: [
      {
        path: ROUTES.LOGIN,
        element: <Login />,
      },
      {
        path: ROUTES.AUTH,
        element: <Auth />,
      },
    ],
  },
  {
    element: <PrivateLayout />,
    children: [
      {
        element: <Dashboard />,
        children: [
          {
            path: ROUTES.BOOKINGSDETAIL,
            element: <BookingDetail />,
          },
          {
            path: ROUTES.PATIENTS,
            element: <Patient />,
          },
          {
            path: ROUTES.PATIENTSDETAIL,
            element: <PatientDetail />,
          },
          {
            path: ROUTES.CAREPROVIDERS,
            element: <CareProvider />,
          },
          {
            path: ROUTES.CAREPROVIDERSDETAIL,
            element: <CareProviderDetail />,
          },
          {
            path: ROUTES.COURSESESSIONS,
            element: <CourseSession />,
          },
          {
            path: ROUTES.COURSESESSIONSDETAIL,
            element: <CourseSessionDetail />,
          },
          {
            path: ROUTES.TRAINING,
            element: <Training />,
          },
          {
            path: ROUTES.REPORTS,
            element: <Reports />,
            children: [
              {
                path: ROUTES.TRIALSLOTS,
                element: <TrialSlots />,
              },
              {
                path: ROUTES.BOOKINGS,
                element: <Booking />,
              },
              {
                path: ROUTES.TRIALANALYSIS,
                element: <TrialAnalysis />,
              },
            ],
          },
          {
            path: ROUTES.LABS,
            element: <Tech />,
            children: [
              {
                path: ROUTES.APPVERSION,
                element: <AppVersion />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <Login />,
  },
]);

export default function AppRoutes() {
  return (
    <Suspense fallback={<Loader height="100vh" />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}
