import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "store/rtkquery/base";

export const appversions = createApi({
  reducerPath: "appversion",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getAppVersions: builder.query({
      query: () => ({
        url: "/apps/search",
      }),
    }),
    addOrEditAppVersion: builder.mutation({
      query: ({ id, data }) => ({
        url: `/apps/${id || ""}${id ? "/update" : "save"}`,
        method: "POST",
        data,
      }),
    }),
    deleteAppVersion: builder.mutation({
      query: ({ id }) => ({
        url: `/apps/${id}/delete`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useLazyGetAppVersionsQuery,
  useAddOrEditAppVersionMutation,
  useDeleteAppVersionMutation,
} = appversions;
