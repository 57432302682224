import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "store/rtkquery/base";

export const livesessions = createApi({
  reducerPath: "livesessions",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getLiveSessionRecording: builder.query({
      query: (params) => ({
        url: "/live/session/recording/get",
        params,
      }),
    }),
  }),
});

export const { useLazyGetLiveSessionRecordingQuery } = livesessions;
