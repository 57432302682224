import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "store/rtkquery/base";

export const trialreports = createApi({
  reducerPath: "trialreport",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getUserTrialSlots: builder.query({
      query: (params) => ({
        url: "/trial/slots/get",
        params,
      }),
    }),
    getTrialSlotCoachBreakdown: builder.query({
      query: (params) => ({
        url: "/ops/report/availability",
        params,
      }),
    }),
  }),
});

export const {
  useLazyGetUserTrialSlotsQuery,
  useLazyGetTrialSlotCoachBreakdownQuery,
} = trialreports;
