import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "store/rtkquery/base";

export const careproviders = createApi({
  reducerPath: "careproviders",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getCareProvider: builder.query({
      query: (body) => ({
        url: "/ops/cp/search",
        params: body,
      }),
      providesTags: (result) =>
        result?.data
          ? [
              ...(result?.data || []).map(({ id }) => ({
                type: "CareProviders",
                id,
              })),
              { type: "CareProviders", id: "LIST" },
            ]
          : [{ type: "CareProviders", id: "LIST" }],
    }),
    getCareProviderBasicDetails: builder.query({
      query: ({ careproviderId: cpId }) => ({
        url: `/ops/cp/${cpId}/details`,
      }),
    }),
    getCareProviderRecurringSlots: builder.query({
      query: ({ careproviderId: cpId }) => ({
        url: `/ops/cp/${cpId}/recurring/slot/cp-slots`,
      }),
      providesTags: (result) =>
        result?.data
          ? [
              ...(result?.data || []).map(({ id }) => ({
                type: "Slots",
                id,
              })),
              { type: "Slots", id: "LIST" },
            ]
          : [{ type: "Slots", id: "LIST" }],
    }),
    saveProviderRecurringSlots: builder.mutation({
      query: ({ careproviderId: cpId, data }) => ({
        url: `/ops/cp/${cpId}/recurring/slot/save`,
        method: "POST",
        data,
        invalidatesTags: (result, error, { id }) => [{ type: "Slots", id }],
      }),
    }),
    registerOrUpdateCareProvider: builder.mutation({
      query: ({ data, careprovierId }) => ({
        url: `/care-provider/${careprovierId || ""}`,
        method: careprovierId ? "PUT" : "POST",
        data,
        invalidatesTags: (result, error, { id }) => [
          { type: "CareProviders", id },
        ],
      }),
    }),
    getCareProviderSkills: builder.query({
      query: ({ careproviderId: cpId }) => ({
        url: `/ops/cp/${cpId}/skills/getAll`,
      }),
      providesTags: (result) =>
        result?.data
          ? [
              ...(result?.data || []).map(({ id }) => ({
                type: "CareProviderSkills",
                id,
              })),
              { type: "CareProviderSkills", id: "LIST" },
            ]
          : [{ type: "CareProviderSkills", id: "LIST" }],
    }),
    addCareProviderSkills: builder.mutation({
      query: ({ careproviderId: cpId, data, skillId }) => ({
        url: `/ops/cp/${cpId}/skills/${skillId || "save"}`,
        method: skillId ? "PUT" : "POST",
        data,
        invalidatesTags: (result, error, { id }) => [
          { type: "CareProviderSkills", id },
        ],
      }),
    }),
    removeCareProviderSkills: builder.mutation({
      query: ({ careproviderId: cpId, skillId }) => ({
        url: `/ops/cp/${cpId}/skills/${skillId}`,
        method: "DELETE",
        invalidatesTags: (result, error, { id }) => [
          { type: "CareProviderSkills", id },
        ],
      }),
    }),
    getCareProviderSkillConstants: builder.query({
      query: () => ({
        url: `/master/skill/types`,
      }),
    }),
    fetchAllCareProviders: builder.query({
      query: (data) => ({
        url: "/care-provider/get-eligible",
        method: "POST",
        data,
      }),
    }),
    fetchAvailableCoachRecurringSlots: builder.query({
      query: (params) => ({
        url: "/availability/recurring/provider-slots",
        params,
      }),
    }),
    getCareProviderAvailability: builder.query({
      query: (params) => ({
        url: "/availability/provider-slots",
        params,
      }),
    }),
    getCareProviderLoginUrl: builder.query({
      query: ({ careproviderId }) => ({
        url: `/ops/cp/${careproviderId}/generateLoginUrl`,
      }),
    }),
    getCareProviderSchedule: builder.query({
      query: ({ careproviderId, params }) => ({
        url: `/care-provider/${careproviderId}/schedule/get`,
        params,
      }),
    }),
    getCareProviderDailySlots: builder.query({
      query: ({ careproviderId, params }) => ({
        url: `/care-provider/${careproviderId}/slot/cp-slots`,
        params,
      }),
    }),
    cancelCareProviderDailySlots: builder.mutation({
      query: ({ careproviderId, data }) => ({
        url: `/care-provider/${careproviderId}/slot/cancel`,
        method: "PUT",
        data,
      }),
    }),
    addCareProviderDailySlots: builder.mutation({
      query: ({ careproviderId, data }) => ({
        url: `/care-provider/${careproviderId}/slot/save`,
        method: "POST",
        data,
      }),
    }),
    getCoachSlotsAtSpecificTime: builder.query({
      query: ({ params }) => ({
        url: "/availability/providers-for-slot",
        params,
      }),
    }),
    updateCoachForSchedule: builder.mutation({
      query: ({ scheduleId, data }) => ({
        url: `/schedule/${scheduleId}/change-host-cp`,
        method: "POST",
        data,
      }),
    }),
    getCoachPatients: builder.query({
      query: ({ careproviderId }) => ({
        url: `/care-provider/${careproviderId}/patients`,
      }),
    }),
    cancelSchedule: builder.mutation({
      query: ({ scheduleId, data }) => ({
        url: `/schedule/${scheduleId}/cancel`,
        method: "POST",
        data,
      }),
    }),
  }),
});

export const {
  useLazyGetCareProviderQuery,
  useLazyGetCareProviderBasicDetailsQuery,
  useLazyGetCareProviderSkillsQuery,
  useLazyGetCareProviderRecurringSlotsQuery,
  useSaveProviderRecurringSlotsMutation,
  useRegisterOrUpdateCareProviderMutation,
  useAddCareProviderSkillsMutation,
  useRemoveCareProviderSkillsMutation,
  useGetCareProviderSkillConstantsQuery,
  useLazyFetchAllCareProvidersQuery,
  useLazyFetchAvailableCoachRecurringSlotsQuery,
  useLazyGetCareProviderAvailabilityQuery,
  useLazyGetCareProviderLoginUrlQuery,
  useLazyGetCareProviderScheduleQuery,
  useLazyGetCareProviderDailySlotsQuery,
  useCancelCareProviderDailySlotsMutation,
  useAddCareProviderDailySlotsMutation,
  useLazyGetCoachSlotsAtSpecificTimeQuery,
  useUpdateCoachForScheduleMutation,
  useGetCoachPatientsQuery,
  useCancelScheduleMutation,
} = careproviders;
