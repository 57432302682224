import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "store/rtkquery/base";

export const patients = createApi({
  reducerPath: "patients",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getPatient: builder.query({
      query: (body) => ({
        url: "/ops/patient/search",
        params: body,
      }),
    }),
    getPatientBasicDetails: builder.query({
      query: ({ patientId }) => ({
        url: `/ops/patient/${patientId}/details`,
      }),
      providesTags: ["Patient"],
    }),
    getPatientHealthRecord: builder.query({
      query: ({ patientId }) => ({
        url: `/ops/patient/${patientId}/health-record`,
      }),
    }),
    getPatientNotes: builder.query({
      query: ({ patientId }) => ({
        url: `/ops/patient/${patientId}/cp-notes`,
      }),
    }),
    getPatientHealthRecordCollection: builder.query({
      query: ({ patientId }) => ({
        url: `/ops/patient/${patientId}/health-record-collection/get`,
      }),
    }),
    getPatientSchedules: builder.query({
      query: ({ patientId }) => ({
        url: `/ops/patient/${patientId}/schedules`,
      }),
      providesTags: (result) =>
        result?.data
          ? [
              ...(result?.data || []).map(({ id }) => ({
                type: "Schedules",
                id,
              })),
              { type: "Schedules", id: "LIST" },
            ]
          : [{ type: "Schedules", id: "LIST" }],
    }),
    cancelPatientSchedule: builder.mutation({
      query: ({ patientId, scheduleId }) => ({
        url: `/ops/patient/${patientId}/schedule/cancel`,
        method: "PUT",
        data: {
          scheduleId,
        },
        invalidatesTags: (result, error, { id }) => [{ type: "Schedules", id }],
      }),
    }),
    getPatientOTP: builder.query({
      query: ({ patientId }) => ({
        url: `/ops/patient/${patientId}/generate-otp`,
      }),
    }),
    applyCareProviderChange: builder.mutation({
      query: ({ patientId, data }) => ({
        url: `/patient/${patientId}/course/change-cp`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["Patient"],
    }),
    applyCourseChange: builder.mutation({
      query: ({ patientId, data }) => ({
        url: `/patient/${patientId}/course/save`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["Patient"],
    }),
    getPatientRecurringSchedules: builder.query({
      query: ({ patientId }) => ({
        url: `/patient/${patientId}/batch/recurring-schedule`,
      }),
      providesTags: (result) =>
        result?.data?.data?.recurringSchedules
          ? [
              ...(result?.data || []).map(({ id }) => ({
                type: "Schedules",
                id,
              })),
              { type: "Schedules", id: "LIST" },
            ]
          : [{ type: "Schedules", id: "LIST" }],
    }),
    savePatientRecurringSchedules: builder.mutation({
      query: ({ patientId, data }) => ({
        url: `/patient/${patientId}/batch/recurring-schedule`,
        method: "POST",
        data,
        invalidatesTags: (result, error, { id }) => [{ type: "Schedules", id }],
      }),
    }),
    registerOrUpdatePatient: builder.mutation({
      query: ({ data, patientId }) => ({
        url: `/ops/patient/${patientId || "add"}`,
        method: patientId ? "PUT" : "POST",
        data,
        invalidatesTags: (result, error, { id }) => [{ type: "Patient", id }],
      }),
    }),
    bookTrialSchedule: builder.mutation({
      query: ({ data }) => ({
        url: `/trial/schedule/book`,
        method: "POST",
        data,
      }),
    }),
    bookSchedule: builder.mutation({
      query: ({ patientId, data }) => ({
        url: `/patient/${patientId}/schedule/book`,
        method: "POST",
        data,
      }),
    }),
    syncCurriculum: builder.query({
      query: ({ patientId }) => ({
        url: `/patient/${patientId}/schedule/sync-schedule`,
        method: "POST",
      }),
    }),
    markOnboardingComplete: builder.query({
      query: ({ patientId }) => ({
        url: `/patient/${patientId}/setOnboardingDone`,
      }),
    }),
    getDropBoxLink: builder.query({
      query: ({ patientId }) => ({
        url: `/patient/${patientId}/getDropboxLink`,
      }),
    }),
    getCustomCurriculumContent: builder.query({
      query: ({ customRefId }) => ({
        url: `/curriculum/custom/${customRefId}/course-session`,
      }),
    }),
    updateCustomCurriculumContent: builder.mutation({
      query: ({ customRefId, data }) => ({
        url: `/curriculum/custom/${customRefId}/course-session/${data.id ? data.id : ""}`,
        method: data.id ? "PUT" : "POST",
        data,
      }),
    }),
  }),
});

export const {
  useLazyGetPatientQuery,
  useGetPatientBasicDetailsQuery,
  useGetPatientHealthRecordQuery,
  useGetPatientHealthRecordCollectionQuery,
  useGetPatientSchedulesQuery,
  useLazyGetPatientSchedulesQuery,
  useCancelPatientScheduleMutation,
  useLazyGetPatientOTPQuery,
  useApplyCareProviderChangeMutation,
  useApplyCourseChangeMutation,
  useGetPatientRecurringSchedulesQuery,
  useSavePatientRecurringSchedulesMutation,
  useRegisterOrUpdatePatientMutation,
  useBookTrialScheduleMutation,
  useBookScheduleMutation,
  useLazySyncCurriculumQuery,
  useLazyMarkOnboardingCompleteQuery,
  useLazyGetDropBoxLinkQuery,
  useLazyGetCustomCurriculumContentQuery,
  useUpdateCustomCurriculumContentMutation,
} = patients;
