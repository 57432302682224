import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "store/rtkquery/base";

export const trainings = createApi({
  reducerPath: "training",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getOnDemandLiveSession: builder.query({
      query: () => ({
        url: "/live/session/vc-provider/get",
        params: {
          scheduleId: Date.now() + Math.ceil(Math.random() * 10000),
        },
      }),
    }),
  }),
});

export const { useLazyGetOnDemandLiveSessionQuery } = trainings;
