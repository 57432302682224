import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import { getAuthCookie } from "helpers/utils";
import { ROUTES } from "./routes";

export default function PublicLayout() {
  const token = getAuthCookie();
  const [queryParams] = useSearchParams();
  const redirectUrl = queryParams.get("redirectUrl");

  if (token && redirectUrl) {
    return <Navigate replace={redirectUrl} />;
  }
  if (token) {
    return <Navigate to={ROUTES.DASHBOARD} />;
  }
  return <Outlet />;
}
