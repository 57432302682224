import { createTheme } from "@mui/material/styles";

export default createTheme({
  spacing: (factor) => `${factor * 2}px`,
  palette: {
    type: "light",
    primary: {
      main: "#E91E63",
    },
    secondary: {
      main: "#ff9100",
    },
  },
  typography: {
    fontFamily: "'Open Sans', Helvetica, Arial, sans-serif",
    h3: {
      fontSize: "26px",
      fontWeight: 900,
    },
    h4: {
      fontSize: "20px",
      fontWeight: 800,
      margin: "0 !important",
    },
    h5: {
      fontSize: "18px",
      lineHeight: "25px",
      fontWeight: 800,
    },
    regular: {
      fontSize: "16px",
    },
    small: {
      fontSize: 14,
    },
    tiny: {
      fontSize: "12px",
      lineHeight: "16px",
    },
    subHeading: {
      fontSize: 16,
      fontWeight: "500",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
            font-family: ''Open Sans', Helvetica, Arial, sans-serif';
            font-style: normal;
            font-display: swap;
            font-weight: 500;
        }
      `,
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#FF9395",
          textDecoration: "none",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
          borderRadius: 10,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: "600px !important",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "red",
          transition: "0.25s opacity",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
  },
});
