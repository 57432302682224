import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "store/rtkquery/base";

export const bookings = createApi({
  reducerPath: "bookings",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getBookings: builder.query({
      query: (body) => ({
        url: "/ops/report/slot-schedules",
        params: body,
      }),
    }),
    getBookingsBasicDetails: builder.query({
      query: ({ bookingId }) => ({
        url: `/ops/session/${bookingId}/details`,
      }),
    }),
  }),
});

export const { useLazyGetBookingsQuery, useGetBookingsBasicDetailsQuery } =
  bookings;
