// /caching/node/clear-keys

import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "store/rtkquery/base";

// Define a service using a base URL and expected endpoints
export const system = createApi({
  reducerPath: "system",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    clearCache: builder.query({
      query: ({ path, body }) => ({
        url: `/caching/node/${path}`,
        method: "POST",
        data: body,
      }),
    }),
  }),
});

export const { useLazyClearCacheQuery } = system;
